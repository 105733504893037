import * as React from 'react'
import { Link } from 'gatsby'



const Navigation = () => {
  return (
      <nav className="col-lg-3 col-12 order-lg-1 px-3">
        <ul>
          <li><Link to="/">Home</Link></li>
          <li><Link to="/about">About Me</Link></li>
          <li><Link to="/projects">Projects</Link></li>
          <li><Link to="/contact">Contact</Link></li>
        </ul>
      </nav>
  )
}

export default Navigation